<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Gebruikers ({{ apiData.is_active ? 'Actief' : 'Archief/Inactief' }})
    </h1>
    <button v-if="apiData.is_active" @click="handleToevoegen">
      + Gebruiker toevoegen
    </button>
    <div :key="apiData.is_active" class="py-4">
      <UITableResponsive :items="items" :headers="headers" :suffix-headers="['Acties']">
        <template #colgroup>
          <colgroup>
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-128">
            <col class="w-auto">
          </colgroup>
        </template>
        <template #item-ID="{ item }">
          {{ item.id }}
        </template>
        <template #item-Naam="{ item }">
          {{ [item.firstname, item.lastname].join(' ').trim() }}
        </template>
        <template #item-GSM="{ item }">
          {{ item.phone || '/' }}
        </template>
        <template #item-Commisie_%="{ item }">
          {{ item.commisie ? `${item.commisie} %` : '/' }}
        </template>
        <template #item-Cash="{ item }">
          {{ item.is_cash_toestaan ? 'Toestaan' : 'Vermeiden' }}
        </template>
        <template #item-Bestuurderspas="{ item }">
          {{ item.bestuurderspas || '?' }}
        </template>
        <template #item-Type="{ item }">
          {{ item.type || '?' }}
          <small v-if="item.type === 'onderaannemer'" class="block font-bold italic">KBO: {{ item.kbo_nummer || '?' }}</small>
        </template>
        <template #item-Wagen="{ item }">
          <small v-if="!item.wagen_id" class="text-red-500">Geen wagen toegekend</small>
          <UISelectWagen
            v-else
            v-model="item.wagen_id"
            class="w-full"
            active
            disabled
          />
        </template>
        <template #item-Acties="{ item }">
          <div class="flex flex-row items-center gap-2">
            <router-link
              v-if="!store.getters.isKantoorPermission"
              target="_blank"
              :to="{ name: item.type === 'onderaannemer' ? 'PlatformUsersWinstmargeOnderaanneming' : 'PlatformUsersRendabiliteit', query: { chauffeur_id: item.id } }"
              class="btn bg-teal-400 text-white"
            >
              <i class="fas fa-money-check-alt"></i>
            </router-link>
            <button v-if="apiData.is_active && !store.getters.isKantoorPermission" class="btn warn" @click="handleBewerken(item)">
              Wijzigen
            </button>
            <button
              class="btn"
              :class="{
                error: apiData.is_active,
                success: !apiData.is_active,
              }"
              @click="setActivation(item.id)"
            >
              {{ apiData.is_active ? 'Desactiveren' : 'Heractiveren' }}
            </button>
          </div>
        </template>
      </UITableResponsive>
      <UITablePagination
        v-model:limit="limit"
        v-model:offset="offset"
        class="mt-2"
        :count="count"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, inject, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UISelectWagen from '@/components/UI/Select/Wagen.vue'
import UITableResponsive from '@/components/UI/Table/Responsive'

import useModal from '@/hooks/useModal'
import usePaginatedApi from '@/hooks/usePaginatedApi'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const store = useStore()
const route = useRoute()
const axios = inject('axios')
const [openFixedModal] = useModal({ fixed: true })

const apiData = computed(() => ({ is_active: !!(route.meta && route.meta.is_active) }))
const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/users/list', apiData, {
  defaultLimit: 50,
})

const setActivation = async id => {
  await axios
    .patch(`/api/dashboard/users/${apiData.value.is_active ? 'deactivate' : 'activate'}`, { id })
    .then(() => getData())
}

const handleToevoegen = () => {
  openFixedModal('FORM_USER', { callback: getData })
}
const handleBewerken = user => {
  openFixedModal('FORM_USER', { callback: getData, modelValue: clone(user) })
}

watch(
  () => apiData.value.is_active,
  (v, o) => {
    if (v == o) return
    offset.value = 0
    getData()
  },
)

const headers = computed(() => {
  const result = [
    'ID',
    'Naam',
    store.getters.isKantoorPermission ? undefined : 'Email',
    store.getters.isKantoorPermission ? undefined : 'GSM',
    store.getters.isKantoorPermission ? undefined : 'Bestuurderspas',
    store.getters.isKantoorPermission ? undefined : 'Type',
    store.getters.isKantoorPermission ? undefined : 'Commisie_%',
    store.getters.isKantoorPermission ? undefined : 'Cash',
  ].filter(el => el)

  if (!store.getters.isKantoorPermission && apiData.value.is_active) {
    result.push('Wagen')
  }

  return result
})
</script>
