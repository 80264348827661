<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Wagens ({{ apiData.is_active ? 'Actief' : 'Archief/Inactief' }})
    </h1>
    <button v-if="apiData.is_active" @click="handleToevoegen">
      + Wagen toevoegen
    </button>
    <div :key="apiData.is_active" class="py-4">
      <UITableResponsive :items="items" :headers="headers" :suffix-headers="['Acties']">
        <template #item-Acties="{ item }">
          <button
            class="btn"
            :class="{
              error: apiData.is_active,
              success: !apiData.is_active,
            }"
            @click="setActivation(item.id)"
          >
            {{ apiData.is_active ? 'Desactiveren' : 'Heractiveren' }}
          </button>
          <button v-if="apiData.is_active" class="ml-1 btn warn" @click="handleBewerken(item)">
            Wijzigen
          </button>
        </template>
      </UITableResponsive>
      <UITablePagination
        v-model:limit="limit"
        v-model:offset="offset"
        class="mt-2"
        :count="count"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, inject, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UITableResponsive from '@/components/UI/Table/Responsive'

import useModal from '@/hooks/useModal'
import usePaginatedApi from '@/hooks/usePaginatedApi'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const store = useStore()
const route = useRoute()
const axios = inject('axios')
const [openFixedModal] = useModal({ fixed: true })

const apiData = computed(() => ({ is_active: !!(route.meta && route.meta.is_active) }))
const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/wagens/list', apiData, {
  defaultLimit: 50,
})

const setActivation = async id => {
  await axios
    .patch(`/api/dashboard/wagens/${apiData.value.is_active ? 'deactivate' : 'activate'}`, { id })
    .then(() => getData())
}

const handleToevoegen = () => {
  openFixedModal('FORM_WAGEN', { callback: getData })
}
const handleBewerken = wagen => {
  openFixedModal('FORM_WAGEN', { callback: getData, modelValue: clone(wagen) })
}

watch(
  () => apiData.value.is_active,
  (v, o) => {
    if (v == o) return
    offset.value = 0
    getData()
  },
)

const headers = computed(() => {
  return [
    'ID',
    'Naam',
    'Nummerplaat',
    'VIN',
    'Teken',
    'Merk',
    'Model',
    store.getters.isKantoorPermission ? undefined : 'Kilometerstand',
  ].filter(el => el)
})

// const headers = computed(() => {
//   const result = [
//     'ID',
//     'Naam',
//     'Email',
//     'GSM',
//     'Commisie_%',
//     'Type',
//     'Fulltime',
//   ]
//   if (apiData.value.is_active) {
//     result.push('Wagen')
//   }
//   return result
// })

// const route = useRoute()
// const axios = inject('axios')
// const apiData = computed(() => ({ is_active: !!(route.meta && route.meta.is_active) }))
// const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/wagens/list', apiData)
// const setActivation = async id => {
//   await axios.patch(`/api/dashboard/wagens/${apiData.value.is_active ? 'deactivate' : 'activate'}`, { id })
//   items.value = items.value.filter(u => u.id !== id)
// }
// watch(
//   () => apiData.value.is_active,
//   (v, o) => {
//     if (v == o) return
//     offset.value = 0
//     getData()
//   },
// )

// const handleToevoegen = () => {
//   openFixedModal('FORM_USER', { callback: getData })
// }

// const handleBewerken = (user) => {
//   openFixedModal('FORM_USER', { callback: getData, modelValue: clone(user) })
// }
</script>
