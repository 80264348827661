<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Affiliates
    </h1>
    <button @click="handleToevoegen">
      + Affiliate toevoegen
    </button>
    <div class="py-4">
      <UITableResponsive :items="items" :suffix-headers="['Acties']">
        <template #colgroup>
          <colgroup>
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-24">
            <col class="w-24">
            <col class="w-24">
            <col class="w-auto">
          </colgroup>
        </template>
        <template #item-affiliate_perc="{ item }">
          {{ item.affiliate_perc }} %
        </template>
        <template #item-omzet="{ item }">
          &euro;&nbsp;{{ formatPrice(item.omzet) }}
        </template>
        <template #item-commisie="{ item }">
          &euro;&nbsp;{{ formatPrice(item.commisie) }}
        </template>
        <template #item-betaald="{ item }">
          &euro;&nbsp;{{ formatPrice(item.betaald) }}
        </template>
        <template #item-Acties="{ item }">
          <div class="flex flex-row gap-2">
            <button class="btn warn" @click="handleWijzigen(item)">
              Wijzigen
            </button>
            <button class="btn error" @click="handleVerwijderen(item)">
              Verwijderen
            </button>
          </div>
        </template>
      </UITableResponsive>
      <UITablePagination
        v-model:limit="limit"
        v-model:offset="offset"
        class="mt-2"
        :count="count"
      />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import clone from 'just-clone'

import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { formatPrice } from '@/functions/formatNumber'
import useApi from '@/hooks/useApi'
import useModal from '@/hooks/useModal'
import usePaginatedApi from '@/hooks/usePaginatedApi'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const api = useApi()
const [openFixedModal] = useModal({ fixed: true })

const { limit, offset, count, items, getData } = usePaginatedApi('AFFILIATE_LIST')

const handleToevoegen = () => {
  openFixedModal('FORM_AFFILIATE', { callback: getData })
}

const handleWijzigen = affiliate => {
  openFixedModal('FORM_AFFILIATE', { modelValue: clone(affiliate), callback: getData })
}

const handleVerwijderen = affiliate => {
  const message = `Affiliate met email '${affiliate.email}', zeker verwijderen?`
  const callback = () => {
    api('AFFILIATE_DELETE', { id: affiliate.id }).then(() => {
      getData()
    })
  }
  openFixedModal('BEVESTIG_ACTIE', { message, callback })
}
</script>
